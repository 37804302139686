
export const Contact = (props) => {
  
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Application Form</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form
                method="POST"
                action="/confirmation-page"
                name="contactform"
                className="contactForm"
              >
                <input type="hidden" name="form-name" value="contactForm" />
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="firstName"
                        name="firstname"
                        className="form-control"
                        placeholder="First Name"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="middleName"
                        name="middlename"
                        className="form-control"
                        placeholder="Middle Name"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="lastName"
                        name="lastname"
                        className="form-control"
                        placeholder="Last Name"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className="form-control"
                        placeholder="Address"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        className="form-control"
                        placeholder="City"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="province"
                        name="province"
                        className="form-control"
                        placeholder="Province"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="postalcode"
                        name="postalcode"
                        className="form-control"
                        placeholder="Postal Code"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="license"
                        name="driverlicense"
                        className="form-control"
                        placeholder="Driver's License #"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="province"
                        name="province"
                        className="form-control"
                        placeholder="Province"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="class"
                        name="class"
                        className="form-control"
                        placeholder="Class"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="cellphone"
                        name="cellphone"
                        className="form-control"
                        placeholder="Cell Phone"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="date"
                        id="dob"
                        name="dob"
                        className="form-control"
                        placeholder="Date of Birth (D/M ONLY)"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="memberSince"
                        name="membersince"
                        className="form-control"
                        placeholder="Member Since (The date membership issued)"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Other motocycle club membership(s) held"
                    required
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="contentCotainer">
                  <p>
                    In consideration of being granted membership in the Sikh
                    Motorcycle Riders Canada and in consideration of being
                    permitted to participate in any and all club sponsored
                    functions and activities (collectively, the “Events”), I
                    agree to the following:
                  </p>
                  <ol>
                    <li>
                      <b>Liability Release and Assumption of Risk.</b> I
                      understand that my participation in an Event can expose me
                      to dangers both from known and unanticipated risk,
                      including, but not limited to, risk associated with riding
                      my motorcycle to, from or during an Event or interaction
                      with persons, including other members or others at an
                      Event. I willingly and voluntarily assume all such risks,
                      including those of loss, damage or injury, including
                      death, to myself and/or my property from any cause
                      whatsoever. Moreover, acknowledging that risk exist, I,
                      for myself, my heirs, personal representatives and
                      assigns, hereby release, discharge and hold harmless the
                      Sikh Motorcycle Riders Canada, and any of its affiliates,
                      the promoters, the landowners, and the sponsors, if any,
                      co-hosting an Event; the owners and representatives,
                      agents, members, successors, assigns, affiliates, and
                      employees of all of them (collectively “Released
                      Parties'') from any and all claims or liability for
                      personal injury, including death, or property damage I may
                      suffer resulting from, arising in connection with, or
                      related to the Event, including, but not limited to , any
                      claims arising out of the conduct of any Event or
                      Event-related activity. I specifically release the
                      Released Parties and each of them for negligence, in any
                      form of any or all of the Released Parties. In signing
                      this release, I FULLY RECOGNIZE THAT IF I AM HURT AND/OR
                      MY PROPERTY IS DAMAGED IN CONNECTION WITH THE EVENT, I
                      WILL HAVE NO RIGHT TO MAKE A CLAIM OR FILEA LAWSUIT
                      AGAINST THE RELEASED PARTIES, EVEN IF THEY OR ANY OF THEM
                      CAUSED MY DEATH, INJURY OR DAMAGE.
                    </li>
                    <li>
                      <b>Indemnification.</b> I agree to indemnify and hold
                      harmless the Released Parties, individually and/or
                      collectively, from all lawsuits, claims, damages, costs,
                      and attorneys’ fees that arise out of my presence or
                      conduct at an Event and/or my violation or my
                      representative’s violations of any provision of the
                      Application. This provision will apply regardless of
                      whether or not the lawsuit, claim, damages, costs and/or
                      attorneys’ fees arise out of the negligence, in any form,
                      of any of the Released Parties. As I am releasing any
                      claim my family, guardian, representative and/or estate
                      might wish to make by reason of my injury or death, this
                      indemnity provision shall specifically apply to such
                      action on my behalf and/or any such action resulting from
                      my injury or death.
                    </li>
                    <li>
                      <b>Insurance Responsibility.</b> I understand that the
                      Sikh Motorcycle Riders Canada provides neither health
                      and/or life insurance. I assume all responsibility for my
                      doctor and/or hospital expenses and any loss or injury to
                      personal property or myself in which I may become involved
                      in by reason of participating in an Event.
                    </li>
                  </ol>
                  <div className="checkFlex">
                    <input type="checkbox" name="acceptance" required />
                    <p>
                      <b>
                        I authorize Sikh Motorcycle Riders Canada to have my
                        email address, mailing address, and phone numbers for
                        club use only (mailing and contact purposes). Also, by
                        signing below I give authorization to post my photos on
                        Club’s website and Facebook account. I fully understand
                        the bylaws of the club including the disciplinary clause
                        and will abide by them. Aforesaid information given by
                        me is correct and latest to the best of my knowledge.
                        <br />
                        <br />I authorize that Sikh Motorcycle Riders Canada can
                        revoke my membership anytime without any notice.
                      </b>
                    </p>
                  </div>
                  <ol>
                    <li>
                      Bearer knows that Sikh Motorcycle Riders Canada’s Logo
                      have Khanda (religious sign of Sikh faith)
                    </li>
                    <li>
                      At any time of wearing or storage the Logo of Sikh
                      Motorcycle Riders Canada will be done in Respectful Manner
                      according to Sikh faith.
                    </li>
                    <li>
                      At any time of wearing or storage the Logo of Sikh
                      Motorcycle Riders Canada will be done in Respectful Manner
                      according to Sikh faith.
                    </li>
                    <li>
                      No other patch or patches are allowed on same jacket or
                      any other garments, only these exceptions are allowed:
                      <ul>
                        <li>Patch of any countries Flag (National)</li>
                        <li>Patch of Provincial Flag</li>
                        <li>Patch of city</li>
                        <li>Patch of Motorcycle company make or model.</li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <h3>For any enquiries</h3>
            {/* <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div> */}
            {/* <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div> */}
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          {/* <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
