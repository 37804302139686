export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default'>
      <div className='container'>
      <img src="img/sitelogo.png" className="img-responsive siteLogo" alt="espan trucking" style={{width: '80px', margin: '0 auto'}}/>
      <h2 style={{textAlign: 'center', margin: '10px 0px 0px'}}>Sikh Motorcycle Riders Canada</h2>
      </div>
    </nav>
  )
}
